@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #e94e24;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.modal::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.dog {
  float: right;
  width: auto;
  height: 100%;
  display: block;
  object-fit: contain;
  shape-outside: polygon(
    11.02% 9.99%,
    6.15% 20.13%,
    4.62% 28.74%,
    0.4% 36.13%,
    12.47% 67.55%,
    28.28% 70.72%,
    31.35% 78.73%,
    22.48% 90.02%,
    24.24% 100.14%
  );
}
.shape1 {
  background-color: white;
  z-index: 99;
}
@media screen and (max-width: 1024px) {
  .dog {
    width: 60%;
    height: auto;
    float: none;
    align-self: flex-end;
    margin-right: -2.4rem;
  }
}
@media screen and (max-width: 500px) {
  .dog {
    width: 80%;
    height: auto;
    float: none;
    align-self: flex-end;
    margin-right: -2.4rem;
  }
}
.Toastify {
  font-size: 12px;
}
